import { ApolloProvider } from "@apollo/client";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import {
  getTokenRedirect,
  msalInstance,
  setCustomerOrganizations,
  setLocalAccountId,
} from "./auth/authHandler";
import { LoadingElement } from "./components/Elements";

export const AuthLoader = () => {
  const [AppComponent, setAppComponent] = useState<React.JSX.Element>();
  let initialized = false;
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const handleAuthEvent = async (event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        const payload = event.payload as AuthenticationResult;
        msalInstance.setActiveAccount(payload.account);

        sessionStorage.setItem(
          "localAccountId",
          payload.account.localAccountId,
        );
        sessionStorage.setItem("idToken", payload.idToken);
        setCustomerOrganizations();

        if (!initialized) {
          const { StoreProvider } = await import("./providers/StoreProvider");
          const { client } = await import("./services/ApolloClient");
          const { default: App } = await import("./App");

          setAppComponent(
            <ApolloProvider client={client}>
              <StoreProvider>
                <HashRouter>
                  <App />
                </HashRouter>
              </StoreProvider>
            </ApolloProvider>,
          );
          initialized = true;
        }
      }
    };
    const callbackId = msalInstance.addEventCallback(handleAuthEvent);

    if (isAuthenticated && msalInstance.getAllAccounts().length) {
      if (!msalInstance.getActiveAccount()) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        setLocalAccountId(msalInstance.getAllAccounts()[0].localAccountId);
        // Trigger EventType.ACQUIRE_TOKEN_SUCCESS which loads the AppComponent
        getTokenRedirect();
      }
    }
    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    AppComponent ?? <LoadingElement label="Preparing your secure session..." />
  );
};
