import React from "react";

import { BaseTextFieldProps, FormControlLabel, TextField } from "@mui/material";

import "./inputElement.scss";

/** @category Input */
export interface InputElementProps extends BaseTextFieldProps {
  label?: string;
  value?: string;
  type?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

/**
 * Renders wrapper around the [Mui Input](https://mui.com/material-ui/react-switch/)
 * @param options
 * @category Switch
 */
export const InputElement = ({
  label,
  type = "text",
  value = "",
  onChange,
  ...props
}: InputElementProps) => {
  const inputElement = (
    <TextField type={type} value={value} onChange={onChange} {...props} />
  );
  return (
    <FormControlLabel
      label={label}
      control={inputElement}
      labelPlacement="start"
    />
  );
};
