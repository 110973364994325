import { gql } from "@apollo/client";

export const SNAPSHOT_FIELDS = gql`
  fragment SnapshotFields on SnapshotNode {
    createdTimestamp
    name
    displayName
    state
    uid
    clearingCorridorMetricsCount
    bounds
  }
`;

export const SNAPSHOT_THUMBNAIL_FIELD = gql`
  fragment SnapshotThumbnailField on SnapshotNode {
    thumbnail
  }
`;

export const SNAPSHOT_DISPLAY_NAME = gql`
  fragment SnapshotDisplayName on SnapshotNode {
    displayName
  }
`;
