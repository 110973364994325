import React, { useEffect, useRef, useState } from "react";

import {
  CircularProgress,
  CircularProgressProps,
  SxProps,
} from "@mui/material";
import { ButtonElement } from "./ButtonElement";
import "./loadingElement.scss";

const LOADING_TIMEOUT = 5000;

export const LoadingElement = ({
  top = "calc(50% - 40px)",
  left = "calc(50% - 40px)",
  position = "absolute",
  label,
  ...props
}: CircularProgressProps & {
  left?: string;
  top?: string;
  position?: string;
  label?: string;
}) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [timeoutMessage, setTimeoutMessage] = useState<React.JSX.Element>();
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (labelRef.current) {
      setLabelWidth(labelRef.current.offsetWidth);
    }
    if (label && !timeoutMessage) {
      timeoutId = setTimeout(
        () =>
          setTimeoutMessage(
            <>
              This is taking longer than expected...
              <br />
              <ButtonElement
                name="Try refreshing the page"
                onClick={() => window.location.reload()}
                className="refresh-button"
              />
            </>,
          ),
        LOADING_TIMEOUT,
      );
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [label, timeoutMessage]);

  return (
    <div>
      <CircularProgress
        {...props}
        sx={{ ...props.sx, position, top, left } as SxProps}
      />
      {label ? (
        <div
          ref={labelRef}
          className="loading-label-container"
          style={{
            left: `calc(50% - ${labelWidth / 2 + 20}px)`, // Centers the label dynamically
          }}
        >
          {timeoutMessage ?? label}
        </div>
      ) : null}
    </div>
  );
};
